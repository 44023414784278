import React from "react";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom";
import { Appdevelopmentdata } from "../../Componentss/ServicesCardData/Data.js";
import { Appdeveopment } from "../../Componentss/ServicesCardData/Data.js";

const Appdevelopment = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>App Development - TechCreator</title>
        <meta
          name="description"
          content="At TechCreator Software House, we offer custom app development services and solutions to help your business succeed."
        />
        <meta
          name="keywords"
          content="TechCreator, TechCreator app development services, mobile app development, TechCreator Software, TechCreator Mobile Solutions, app development, software industry, mobile solutions"
        />
        <link rel="canonical" href="/app-development" />
      </Helmet>
      <section className="mt-14 h-[400px] lg:h-[600px] bg-black mb-5 flex items-center ">
        <Fade left duration={1000}>
          <div className="container flex flex-col lg:items-start  mb-4 px-4 ">
            <div className="lg:w-[70%] xs:w-[80%]  w-full">
              <h1 className="text-[blueviolet] text-2xl md:text-4xl lg:text-7xl subpixel-antialiased lg:leading-snug leading-relaxed font-bold tracking-normal">
                Custom Mobile App Development Services
              </h1>
            </div>
            <div className="lg:w-3/4 xs:w-[90%] sm:w-[70%]">
              <p className="text-white pt-2  text-base leading-relaxed lg:text-3xl lg:leading-10">
                We build innovative mobile apps tailored to your business needs
                using cutting edge technologies.
              </p>
            </div>
          </div>
        </Fade>
      </section>

      <section className="h-[300px] lg:h-[400px]  mb-2 flex justify-center items-center">
        <div className="container flex flex-col sm:flex-row lg:flex-row justify-between items-center">
          <Fade left duration={1000}>
            <div className="lg:w-3/5  lg:mb-0 sm:w-3/5 p-2  sm:mb-8 md:mb-0 pt-3">
              <h1 className="text-2xl sm:text-4xl sm:leading-relaxed lg:text-6xl md:text-5xl md:leading-relaxed font-bold leading-normal lg:leading-normal text-[blueviolet]">
                Elevate your business with our mobile app solutions
              </h1>
            </div>
          </Fade>
          <Fade
            bottom
            delay={1000}
            duration={1000}
            style={{ overflow: "hidden" }}
          >
            <div className="lg:w-2/5 sm:w-2/5 p-2 mb-2">
              <p className="text-base lg:text-2xl text-justify text-black md:text-lg">
                Our team excels in creating mobile apps that offer seamless user
                experiences across all devices. We specialize in both iOS and
                Android platforms, ensuring that your app reaches a wide
                audience. From intuitive design to robust functionality, we
                develop apps that not only meet but exceed your expectations.
              </p>
            </div>
          </Fade>
        </div>
      </section>

      <section className="h-auto container mb-2 px-3">
        <Fade bottom duration={1000}>
          <div className="mt-3">
            <h1 class="lg:text-5xl sm:text-4xl md:text-5xl text-3xl text-[blueviolet] tracking-wide  font-bold pb-2 pt-4">
              Services
            </h1>
          </div>
        </Fade>

        <div className="grid  grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-4">
          {Appdevelopmentdata.map((item) => {
            return (
              <Fade bottom duration={1000}>
                <div className="bg-slate-50 h-[20rem]  container relative group hover:border-b-4 hover:border-blue-200 transition-all duration-300 overflow-hidden hover:shadow-lg cursor-pointer">
                  <h2 className=" text-8xl text-gray-200 pl-0 lg:pl-10 lg:pt-7 absolute inset-0 transition-opacity duration-300 group-hover:opacity-0">
                    {item.Number}
                  </h2>
                  <div className="flex flex-col justify-center h-full relative z-10 lg:p-6  mt-10 hover:mt-0">
                    <h3 className="font-bold pt-3  text-[blueviolet] text-2xl  transition-all duration-300 group-hover:text-2xl">
                      {item.Title}
                    </h3>
                    <p className="text-black text-justify  transition-opacity duration-300 group-hover:opacity-100">
                      {item.Description}

                      <span className="hidden group-hover:block text-black transition-all duration-300 ">
                        {item.hovertext}
                      </span>
                    </p>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </section>
      <section className="h-[400px]  flex items-center justify-center py-10">
        <div className="container flex flex-col sm:flex-row items-center ">
          <div className="w-full sm:w-3/5 lg:w-[70%]  mb-2 sm:mr-10 text-center sm:text-left">
            <Fade left duration={1000}>
              <h2 className="text-[blueviolet] text-2xl  md:text-5xl md:leading-normal xs:text-3xl lg:text-6xl lg:leading-normal sm:text-4xl leading-snug sm:leading-relaxed pr-5 sm:pr-0 lg:pr-5 font-bold  sm:text-left">
                Looking for ways to improve your business?
              </h2>
            </Fade>
          </div>

          <Fade bottom duration={1000}>
            <div className="w-full sm:w-[60%] flex flex-col items-center text-center  md:mr-9">
              <p className="text-xl sm:text-3xl">Our team is here to help!</p>
              <p className="text-base sm:text-lg">
                So, reach out now and let’s have a chat!
              </p>
              <button
                className="bg-[blueviolet] text-white px-4 py-2 text-base sm:text-xl rounded hover:shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 mt-4"
                onClick={() => navigate("/contact")}
              >
                Get in touch
              </button>
            </div>
          </Fade>
        </div>
      </section>
      <section className="h-auto container  mb-4 px-3">
        <Fade bottom duration={1000}>
          <div>
            <h1 class="lg:text-4xl  text-[blueviolet] tracking-wide  font-bold pb-2 pt-4">
              Related services
            </h1>
          </div>
        </Fade>

        <div className="grid h-auto grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-4 mb-3">
          {Appdeveopment.map((val, index) => {
            return (
              <Fade bottom duration={1000} key={index}>
                <div
                  className="h-[20rem] bg-slate-50 hover:bg-slate-200 group relative hover:border-b-4 hover:border-blue-200 transition-all duration-300 overflow-hidden hover:shadow-lg cursor-pointer"
                  onClick={() => {
                    navigate(val.Link);
                  }}
                >
                  <div className="absolute top-4 right-4 transform transition-transform duration-300 group-hover:-rotate-45">
                    {val.icon}
                  </div>
                  {/* Positioned at the bottom left */}
                  <div className="absolute bottom-4 left-16 flex flex-col items-start">
                    <img
                      src={val.image}
                      alt="image not showing"
                      className="w-14 mb-2"
                    />
                    <h2 className="text-lg font-medium">{val.text}</h2>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Appdevelopment;
