import { IoIosSearch } from "react-icons/io";

const tags = ["system design", "dev oops", "python", "typescripts"];

export default function BlogsFilter({ setSearchText, searchText }) {
  return (
    <div className="rightbar fixed-sidebar mx-4">
      <div className="p-3 border border-gray-300">
        <p className="text-xl border-b-[2px] w-fit pb-1 border-blue-400">
          Search
        </p>
        <input
          type="text"
          placeholder="Search..."
          className="p-3 bg-gray-200 w-full rounded-md"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
        <button className="p-2 mt-2 px-4 bg-blue-400 rounded-md">
          <IoIosSearch />
        </button>
      </div>
      <div className="border p-3">
        <div className="border-b-2 border-blue-400 inline pb-1">
          <span>Tags</span>
        </div>
        <hr />
        <div className="flex gap-2 items-center justify-center flex-wrap">
          {tags.map((tag, index) => (
            <button
              key={index}
              type="button"
              className="border p-2 rounded capitalize hover:bg-blue-400 hover:text-white"
            >
              {tag}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
