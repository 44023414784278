import React from "react";
import logo from "../../image/logo.png";
import "./home.css";

const Changeslog = () => {
  return (
    <div className="flex items-center justify-center pb-3 tech-log">
      <img
        src={logo}
        alt="logo"
        className="logo pr-1 object-cover  "
      />
    </div>
  );
};

export default Changeslog;
