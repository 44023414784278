import DevOps from "../Serviespage/DevOps";

export const Graphicdata = [
  {
    image: "/images/trust-and-loyalty-64x64.svg",
    Title: "Build trust and loyalty",
    description:
      "Establish a consistent and impactful brand identity that helps build trust and a lasting relationship with your audience",
  },
  {
    image: "/images/stand-out-64x64.svg",
    Title: "Stand out from the competition",
    description:
      "Our custom design solutions empower you to establish a distinctive identity within your industry, helping you stand out from the crowd.",
  },
  {
    image: "/images/brand-recognition-64x64.svg",
    Title: "Increase brand recognition",
    description:
      "By crafting a consistent brand identity across all digital and physical touchpoints, we help increase your brand recognition and recall.",
  },
  {
    image: "/images/expand-your-reach-64x64.svg",
    Title: "Expand your reach",
    description:
      "Leverage our intuitive and creative designs to appeal to a wider audience, expand your reach, and discover new business opportunities.",
  },
];

export const Relaservices = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),

    image: "/images/cloud-devops-40x40.svg",
    text: "DevOps",
    Link: "/devOps",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/ui-ux-design-40x40.svg",
    text: "UI/UX design",
    Link: "/uiDesign",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/web-dev-40x40.svg",
    text: "Website deveopment",
    Link: "/development",
  },
];

export const wedCardData = [
  {
    Number: "01",
    Title: "UI/UX Design",
    Description:
      "Experience responsive designs and seamless user interactions with our UI/UX services. We create intuitive, scalable interfaces using tools like Adobe Suite and Figma.",
    hoverText:
      " Our user-centric approach enhances satisfaction while fitting your unique brand.",
  },
  {
    Number: "02",
    Title: "Mobile development",
    Description:
      "Develop high-quality mobile apps tailored for both iOS and Android platforms. We ensure seamless performance, user-friendly interfaces, and cross-device compatibility.",
    hoverText: " Elevate your business with custom mobile solutions.",
  },
  {
    Number: "03",
    Title: "Front-end development",
    Description:
      "Create visually stunning, responsive websites with our front-end development services. We prioritize performance, accessibility, ",
    hoverText:
      "and seamless user experiences across all devices, ensuring your site stands out.",
  },
  {
    Number: "04",
    Title: "Back-end development",
    Description:
      "Develop robust and scalable back-end solutions that power your applications. We focus on security, efficiency, ",
    hoverText:
      "and seamless integration with front-end systems, ensuring reliable performance.",
  },
  {
    Number: "05",
    Title: "API integration",
    Description:
      "Integrate custom and third-party APIs to enhance your application's functionality. Our expertise ensures smooth connectivity ",
    hoverText:
      "and efficient data exchange, optimizing your app's capabilities.",
  },
  {
    Number: "06",
    Title: "Cloud DevOps",
    Description:
      "Implement Cloud DevOps strategies to streamline your development process. We optimize deployment, scaling, and management of cloud infrastructure,",
    hoverText:
      " ensuring high availability and reliability for your applications",
  },
];

export const Appdevelopmentdata = [
  {
    Title: " Custom Mobile Apps",
    Description:
      "Tailor-made apps designed to meet your business needs, offering high performance and a unique user experience.",
    Number: "01",
    hovertext:
      "Our solutions work seamlessly across all devices. We ensure your app stands out with reliability and efficiency ",
  },
  {
    Title: "UI/UX Design for Apps ",
    Description:
      "We craft intuitive and visually appealing interfaces that enhance user engagement.",
    Number: "02",
    hovertext:
      " Our designs focus on creating seamless and enjoyable experiences, ensuring that every interaction with your app is both efficient and delightful.",
  },
  ,
  {
    Title: "Cross-Platform Dev",
    Description:
      "Develop cross-platform apps using React Native and Flutter for a unified experience across devices. ",
    Number: "03",
    hovertext:
      " Ensure robust performance and seamless integration. Reach a wider audience with efficient, consistent design.",
  },
  {
    Title: "API Integration",
    Description:
      "Integrate custom and third-party APIs to enhance your app’s capabilities. Improve connectivity and functionality seamlessly.  ",
    Number: "04",
    hovertext: "Boost overall performance and user experience.",
  },
  {
    Title: "App Support & Maintenance",
    Description:
      "Offer ongoing maintenance and support to keep your app updated and secure. Ensure optimal performance and reliability. ",
    Number: "05",
    hovertext: "Keep your app running smoothly.",
  },
  {
    Title: "Native App Dev",
    Description:
      "Build high-performance native apps for iOS and Android, ensuring optimal user experience and functionality. ",
    Number: "06",
    hovertext:
      "Our expert team delivers intuitive, responsive designs with seamless device integration.",
  },
];

export const uiData = [
  {
    Number: "01",
    title: "Website UI/UX",
    description:
      "Engage your audience through responsive, aesthetic, and user-friendly websites.",
    hoverText:
      "Our team works collaboratively with your business to meet your specific needs in designing and improving the experience of your website.",
  },
  {
    Number: "02",
    title: "Web app UI/UX",
    description:
      "Increase user engagement through intuitive and visually appealing web application designs.",
    hoverText:
      "Combining creativity and technology, we create user-friendly web-based products and services that meet the needs of your target audience and help you achieve your business goals.",
  },
  {
    Number: "03",
    title: "Mobile app UI/UX",
    description:
      "Improve your customers’ experience through engaging and interactive mobile application designs.",
    hoverText:
      "We design iOS, Android and even hybrid applications following Apple and Google guidelines. These guidelines help in publishing the application on the store with convenience.",
  },
  {
    Number: "04",
    title: "Digital audit",
    description:
      "Digital products work and show better results when they are timely tested and improved.",
    hoverText:
      "Utilize the expertise of our detailed design audit and get actionable feedback on improving your product or service’s user experience.",
  },
  {
    Number: "05",
    title: "Usability testing",
    description:
      "Test with real users to get the detailed insights that will help improve your product or service.",
    hoverText:
      "Engage us to identify areas of improvement in your web or app design and optimize user experience to maximize conversion.",
  },
  {
    Number: "06",
    title: "User research",
    description:
      "Learn about your users and get to know their needs and behaviours to design the right way!",
    hoverText:
      "We collect and analyse feedback from real users to gain insights into their needs, behaviours, and goals. This, in turn, helps improve the design decisions to create more user-friendly and user-centric products or services.",
  },
];

export const webdeveopment = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),

    image: "/images/cloud-devops-40x40.svg",
    text: "App development",
    Link: "/Appdevelop",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/ui-ux-design-40x40.svg",
    text: "UI/UX design",
    Link: "/uiDesign",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/web-dev-40x40.svg",
    text: "Graphic designing",
    Link: "/graphicdes",
  },
];

export const Appdeveopment = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),

    image: "/images/cloud-devops-40x40.svg",
    text: "UI/UX Design",
    Link: "/uiDesign",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/ui-ux-design-40x40.svg",
    text: "Graphic Designing",
    Link: "/graphicdes",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/web-dev-40x40.svg",
    text: "SEO & content writing",
    Link: "/seoContent",
  },
];

export const uiDesign = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),

    image: "/images/cloud-devops-40x40.svg",
    text: "Graphic Designing",
    Link: "/graphicdes",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/ui-ux-design-40x40.svg",
    text: "Project Management",
    Link: "/projMgmt",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/web-dev-40x40.svg",
    text: "SEO & Content Writing",
    Link: "/seoContent",
  },
];


export const SEOContentd = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),

    image: "/images/cloud-devops-40x40.svg",
    text: "Project Management",
    Link: "/projMgmt",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/ui-ux-design-40x40.svg",
    text: "QA Testing",
    Link: "/qATesting",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/web-dev-40x40.svg",
    text: "Software Maintenance",
    Link: "/softwareMntce",
  },
];


export const ProjectManD = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),

    image: "/images/cloud-devops-40x40.svg",
    text: "DevOps",
    Link: "/devOps",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/ui-ux-design-40x40.svg",
    text: "Software Maintenance",
    Link: "/softwareMntce",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/web-dev-40x40.svg",
    text: "QA Testing",
    Link: "/qATesting",
  },
];


export const QATestingd = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),

    image: "/images/cloud-devops-40x40.svg",
    text: "Project Management",
    Link: "/projMgmt",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/ui-ux-design-40x40.svg",
    text: "SEO & Content writing",
    Link: "/seoContent",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/web-dev-40x40.svg",
    text: "grahic Desiging",
    Link: "/graphicdes",
  },
];

export const SoftwareMntceD = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),

    image: "/images/cloud-devops-40x40.svg",
    text: "Project Management",
    Link: "/projMgmt",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/ui-ux-design-40x40.svg",
    text: "DevOps",
    Link: "/devOps",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/web-dev-40x40.svg",
    text: "QA Testing",
    Link: "/qATesting",
  },
];

export const DevOpsD = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),

    image: "/images/cloud-devops-40x40.svg",
    text: "SOftware Management",
    Link: "/softwareMntce",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/ui-ux-design-40x40.svg",
    text: "SEO & Content writing",
    Link: "/seoContent",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-6 w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>
    ),
    image: "/images/web-dev-40x40.svg",
    text: "Graphic Designing",
    Link: "/graphicdes",
  },
];



export const seoData = [
  {
    Number: "01",
    title: "Keyword Research",
    description:
      "Discover high-impact keywords that drive traffic and conversions.",
    hoverText:
      "Our in-depth keyword research helps you identify the most valuable search terms for your business, ensuring you rank higher on search engines and attract your target audience.",
  },
  {
    Number: "02",
    title: "Content Strategy",
    description: "Create a tailored content strategy to boost your brand.",
    hoverText:
      "We craft a comprehensive content strategy that aligns with your brand goals, ensuring each piece of content speaks directly to your audience and drives engagement.",
  },
  {
    Number: "03",
    title: "SEO Content Writing",
    description:
      "Produce SEO-optimized content that ranks and resonates with readers.",
    hoverText:
      "Our expert writers create high-quality, engaging content tailored to your audience, while also optimizing it for search engines to improve your site's visibility.",
  },
  {
    Number: "04",
    title: "Technical SEO",
    description:
      "Optimize your website’s backend to improve speed, structure, and search rankings.",
    hoverText:
      "We ensure your website meets the latest technical SEO standards, from optimizing load times to fixing broken links, enhancing your overall site performance and searchability.",
  },
  {
    Number: "05",
    title: "Content Audits",
    description: "Evaluate your current content to maximize its effectiveness.",
    hoverText:
      "Our content audits identify gaps and opportunities in your existing content, helping you enhance what's working and revamp what isn't to achieve better results.",
  },
  {
    Number: "06",
    title: "Link Building",
    description:
      "Build a strong backlink profile to increase your site's authority and rankings.",
    hoverText:
      "We implement ethical and effective link-building strategies that help improve your website's authority, driving higher rankings and increased traffic.",
  },
  {
    Number: "07",
    title: "Local SEO",
    description:
      "Boost your local presence with targeted SEO tactics that attract nearby customers.",
    hoverText:
      "We optimize your business for local search results, ensuring you appear in maps and searches when potential customers are looking for services in your area.",
  },
  {
    Number: "08",
    title: "Content Refresh",
    description:
      "Revitalize your existing content to keep it fresh, relevant, and SEO-friendly.",
    hoverText:
      "We update your old content to align with the latest trends, SEO practices, and your evolving brand message, helping it perform better in search results.",
  },
  {
    Number: "09",
    title: "Performance Monitoring",
    description:
      "Track the success of your SEO and content efforts with in-depth analytics.",
    hoverText:
      "We provide ongoing performance monitoring and analysis, offering insights and recommendations to continuously optimize your SEO and content strategies.",
  },
];

export const ProjectManDta = [
  {
    Number: "01",
    title: "Project Planning",
    description:
      "Develop comprehensive project plans that outline tasks, timelines, and resource allocations, ensuring all project objectives are met efficiently.",
    hoverText: "Strategize and structure your project for success.",
  },
  {
    Number: "02",
    title: "Risk Management",
    description:
      "Identify, assess, and mitigate potential risks that could impact the project's success, ensuring smooth project execution.",
    hoverText: "Proactively manage risks to stay on track.",
  },
  {
    Number: "03",
    title: "Task Coordination",
    description:
      "Coordinate tasks among team members to ensure seamless workflow and on-time delivery of each project phase.",
    hoverText: "Enhance team collaboration for efficient execution.",
  },
  {
    Number: "04",
    title: "Quality Assurance",
    description:
      "Implement quality control processes to ensure that project deliverables meet the required standards and client expectations.",
    hoverText: "Deliver quality results that exceed expectations.",
  },
  {
    Number: "05",
    title: "Stakeholder Communication",
    description:
      "Maintain clear and consistent communication with all stakeholders to keep everyone informed about project progress and updates.",
    hoverText: "Keep stakeholders informed and engaged.",
  },
  {
    Number: "06",
    title: "Resource Management",
    description:
      "Optimize the allocation and utilization of resources, including personnel, equipment, and budget, to maximize project efficiency and success.",
    hoverText: "Efficiently manage resources for optimal outcomes.",
  },
];

export const QATestingdata = [
  {
    Number: "01",
    title: "Manual Testing",
    description:
      "Our meticulous manual testing identifies issues that automated tests might miss, ensuring your software functions flawlessly",
    hoverText:
      "Detects functional, usability, and performance issues manually for precise quality assurance",
  },
  {
    Number: "02",
    title: "Automated Testing",
    description:
      "Speed up your testing process with our state-of-the-art automated testing tools for reliable and repeatable results",
    hoverText:
      "Automated tests save time and increase coverage, catching bugs early in the development cycle",
  },
  {
    Number: "03",
    title: " Performance Testing",
    description:
      "Measure the responsiveness, stability, and speed of your software under varying workloads.",
    hoverText:
      "Optimizes performance through load, stress, and endurance testing",
  },
  {
    Number: "04",
    title: "Security Testing",
    description:
      "Protect your application from vulnerabilities with our in-depth security testing services.",
    hoverText:
      "Text: Identifies security loopholes, ensuring your application is safe from cyber threats",
  },
  {
    Number: "05",
    title: "Usability Testing",
    description:
      "Improve user experience by ensuring your software is intuitive, accessible, and user-friendly.",
    hoverText:
      "Evaluates the ease of use and user satisfaction of your product.",
  },
  {
    Number: "06",
    title: "Load Testing",
    description:
      " Test your system's performance under various load conditions to ensure stability",
    hoverText:
      "Simulate real-world user loads to optimize performance under high traffic.",
  },
];

export const SoftwareMntcedata = [
  {
    Number: "01",
    title: "Regular Updates",
    description:
      "Ensure your software remains compatible with new technologies and standards through our routine updates and enhancements.",
    hoverText:
      "Keep your application current with regular updates, minimizing compatibility issues and leveraging new features.",
  },
  {
    Number: "02",
    title: "Bug Fixes",
    description:
      "Swiftly address and resolve any bugs or issues to maintain the stability and reliability of your software.",
    hoverText:
      "Efficient bug fixing ensures a stable and reliable application experience for your users.",
  },
  {
    Number: "03",
    title: "Performance Optimization",
    description:
      " Improve the speed and efficiency of your software with our performance optimization techniques.",
    hoverText:
      "Enhance your software’s performance and user experience through targeted optimization strategies.",
  },
  {
    Number: "04",
    title: "Security Patches",
    description:
      "Protect your software from vulnerabilities with timely security patches and updates.",
    hoverText:
      "Safeguard your application from potential threats with essential security patches.",
  },
  {
    Number: "05",
    title: " Technical Support",
    description:
      "Access dedicated support to troubleshoot and resolve any issues that arise, ensuring your software remains operational.",
    hoverText:
      " Reliable technical support provides quick resolutions to any issues, minimizing downtime.",
  },
  {
    Number: "06",
    title: "Backup and Recovery",
    description:
      "Safeguard your valuable data with our reliable backup and recovery solutions, ensuring minimal data loss and quick restoration in case of emergencies.",
    hoverText:
      " Protect and recover your data efficiently with our comprehensive backup and recovery services.",
  },
];


export const DevOpsData=[
  {
    Number: "01",
    title: " Continuous Integration",
    description:
      " Automate your build and testing processes with continuous integration, ensuring that code changes are validated and integrated efficiently.",
    hoverText:
      "Achieve faster and more reliable builds with automated testing and integration pipelines.",
  },
  {
    Number: "02",
    title: "Continuous Deployment",
    description:
      "Streamline your release process with continuous deployment, allowing for frequent and reliable software releases.",
    hoverText:
      "Reduce deployment risks and accelerate software delivery with automated release pipelines.",
  },
  {
    Number: "03",
    title: "Infrastructure as Code",
    description:
      "Manage and provision your infrastructure using code to automate and streamline environment setup and maintenance.",
    hoverText:
      "Achieve consistency and scalability with automated infrastructure provisioning and management",
  },
  {
    Number: "04",
    title: "Cloud Infrastructure Management",
    description:
      "Optimize your cloud infrastructure with our management services, ensuring scalability, cost-efficiency, and high performance.",
    hoverText:
      "Enhance your cloud environment with expert management and optimization strategies.",
  },
  {
    Number: "05",
    title: "Automated Testing",
    description:
      "mplement automated testing to ensure code quality and performance across various stages of development and deployment.",
    hoverText:
      "Achieve consistent quality and faster feedback with comprehensive automated testing.",
  },
  {
    Number: "06",
    title: "Monitoring and Logging",
    description:
      "Gain insights into your system’s performance with robust monitoring and logging, ensuring proactive issue detection and resolution.",
    hoverText:
      "Stay ahead of potential issues with real-time monitoring and detailed logging.",
  },
]