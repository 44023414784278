import { useState } from "react";

export default function TagsInput({ tags, setTags }) {
  const [tag, setTag] = useState("");

  const onChangeHandler = (e) => {
    setTag(e.target.value);
  };

  const onKeyDownHandler = (e) => {
    if (e.key === "Enter" && tag.trim()) {
      setTags([...tags, tag.trim()]);
      setTag("");
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="flex my-4 flex-wrap gap-2">
      <div className="flex gap-2 flex-wrap">
        {tags?.map((tag, index) => (
          <div
            key={index}
            className="bg-gray-300 p-1 rounded-md flex items-center justify-center gap-2 px-2"
          >
            <span>{tag}</span>
            <button
              onClick={() => removeTag(index)}
              className="bg-red-600 text-white w-6 rounded-full hover:bg-red-700"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
      <input
        type="text"
        placeholder="Enter Tag"
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        value={tag}
        className="border outline-none p-2 rounded-md flex-grow"
      />
    </div>
  );
}
