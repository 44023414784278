import React from "react";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import { QATestingdata } from "../ServicesCardData/Data";
import BugDetection from "../../image/search_12672061.png"
import  Faster from "../../image/music_12748637.png"
import performance from "../../image/performance_16137979.png"
import Enhanced from "../../image/rating_17526724.png"
import { QATestingd } from "../ServicesCardData/Data";
import { useNavigate } from "react-router-dom";

const QATesting = () => {
  const navigate= useNavigate()
  return (
    <>
      <Helmet>
        <title>QA Testing Services - TechCreator</title>
        <meta
          name="description"
          content="TechCreator offers top-notch QA Testing services to ensure your software is bug-free, reliable, and user-friendly."
        />
        <meta
          name="keywords"
          content="QA Testing, Quality Assurance, Software Testing, Bug Fixing, TechCreator, Manual Testing, Automated Testing"
        />
        <link rel="canonical" href="/" />
      </Helmet>

      <div className="mt-14 bg-black h-[400px] lg:h-[600px] mb-1 flex flex-col justify-center items-start px-2">
        <Fade left duration={1000}>
          <div className="pt-4 container">
            <div className="w-full lg:w-[80%]">
              <h1 className="text-[blueviolet] lg:pb-2 text-xl sm:text-4xl md:text-4xl lg:text-7xl lg:leading-snug subpixel-antialiased tracking-wide font-bold">
                QA Testing Services & Solutions
              </h1>
            </div>
            <div className="w-full lg:w-[70%] sm:w-[70%]">
              <p className="text-white pt-2 lg:leading-10  text-base lg:text-2xl">
                Deliver flawless software experiences with our comprehensive
                quality assurance services, tailored to ensure your product is
                bug-free and fully optimized
              </p>
            </div>
          </div>
        </Fade>
      </div>

      <section className="h-[400px] mb-2 flex justify-center items-center px-2 mt-2">
        <div className="container flex flex-col sm:flex-row justify-between items-center">
          <Fade left duration={1000}>
            <div className="lg:w-[50%] mb-1 lg:mb-0 sm:w-[70%]">
              <h1 className="text-xl sm:text-5xl sm:leading-relaxed lg:text-6xl md:text-5xl md:leading-relaxed font-bold leading-normal lg:leading-normal text-[blueviolet]">
                Comprehensive QA Testing Solutions
              </h1>
            </div>
          </Fade>
          <Fade
            bottom
            delay={1000}
            duration={1000}
            style={{ overflow: "hidden" }}
          >
            <div className="text-base lg:w-2/5 sm:w-[50%] xs:w-[90%]">
              <p className="text-base lg:text-xl text-justify text-black md:text-lg">
                At TechCreator, we provide rigorous QA testing services that
                cover all aspects of software development. Our team ensures your
                product meets the highest standards of quality through both
                manual and automated testing techniques.
              </p>
            </div>
          </Fade>
        </div>
      </section>

      <section className="h-auto container mb-2 px-3 ">
        <Fade bottom duration={1000}>
          <div className="">
            <h1 className="text-xl lg:text-5xl sm:text-4xl  text-[blueviolet] tracking-wide font-bold pb-2 pt-4">
              Services
            </h1>
          </div>
        </Fade>

        <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-4">
          {QATestingdata.map((item) => {
            return (
              <Fade bottom duration={1000}>
                <div className="bg-slate-50 h-[20rem] container relative group hover:border-b-4 hover:border-blue-200 transition-all duration-300 overflow-hidden hover:shadow-lg cursor-pointer">
                  <h2 className=" text-8xl text-gray-200 pl-0 lg:pl-10 lg:pt-7 absolute inset-0 transition-opacity duration-300 group-hover:opacity-0">
                    {item.Number}
                  </h2>
                  <div className="flex flex-col justify-center h-full relative z-10 lg:p-6 mt-10 hover:mt-0">
                    <h3 className="font-bold pt-3 text-[blueviolet] text-2xl transition-all duration-300 group-hover:text-2xl">
                      {item.title}
                    </h3>
                    <p className="text-black text-justify transition-opacity duration-300 group-hover:opacity-100">
                      {item.description}
                      <span className="hidden group-hover:block text-black transition-all duration-300">
                        {item.hoverText}
                      </span>
                    </p>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </section>

      <section className="p-8 rounded-lg container px-3 py-6">
        <h3 className="text-2xl md:text-5xl sm:text-3xl font-bold pb-2 text-[blueviolet] mt-4">
          Benefits
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* <!-- Benefit Item 1 --> */}
          <div className="flex flex-col md:flex-row items-start p-6 rounded-lg shadow bg-slate-50">
            <img
              src={BugDetection}
              alt="Effective Planning"
              className="w-12 h-12 mr-0 md:mr-4 mb-4 md:mb-0 rounded flex-shrink-0"
            />
            <div className="text-left">
              <h4 className="text-xl font-bold text-[blueviolet]">
              Reliable Bug Detection
              </h4>
              <p className="text-black text-base">
              Ensure top-quality software with comprehensive bug detection across all testing stages.
              </p>
            </div>
          </div>

          {/* <!-- Benefit Item 2 --> */}
          <div className="flex flex-col md:flex-row items-start p-6 rounded-lg shadow bg-slate-50">
            <img
              src={Faster}
              alt="Risk Management"
              className="w-12 h-12 mr-0 md:mr-4 mb-4 md:mb-0 rounded flex-shrink-0"
            />
            <div className="text-left">
              <h4 className="text-xl font-bold text-[blueviolet]">
              Faster Time-to-Market
              </h4>
              <p className="text-black text-base">
              Speed up the development process with efficient automated testing, reducing your time to market.
              </p>
            </div>
          </div>

          {/* <!-- Benefit Item 3 --> */}
          <div className="flex flex-col md:flex-row items-start p-6 rounded-lg shadow bg-slate-50">
            <img
              src={performance}
              alt="Task Coordination"
              className="w-12 h-12 mr-0 md:mr-4 mb-4 md:mb-0 rounded flex-shrink-0"
            />
            <div className="text-left">
              <h4 className="text-xl font-bold text-[blueviolet]">
              Cost-Efficient Testing
              </h4>
              <p className="text-black text-base">
              Reduce costs by identifying and fixing bugs early in the development cycle.
              </p>
            </div>
          </div>

          {/* <!-- Benefit Item 4 --> */}
          <div className="flex flex-col md:flex-row items-start p-6 rounded-lg shadow bg-slate-50">
            <img
              src={Enhanced}
              alt="Quality Assurance"
              className="w-12 h-12 mr-0 md:mr-4 mb-4 md:mb-0 rounded flex-shrink-0"
            />
            <div className="text-left">
              <h4 className="text-xl font-bold text-[blueviolet]">
              Enhanced User Satisfaction
              </h4>
              <p className="text-black text-base">
              Deliver an optimized user experience by resolving usability issues.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="h-[400px]  flex items-center justify-center py-10">
        <div className="container flex flex-col sm:flex-row items-center ">
          <div className="w-full sm:w-3/5 lg:w-[70%]  sm:mb-0 sm:mr-10 text-center sm:text-left">
            <Fade left duration={1000}>
              <h2 className="text-[blueviolet] text-2xl  md:text-5xl md:leading-normal xs:text-3xl lg:text-6xl lg:leading-normal sm:text-4xl leading-snug sm:leading-relaxed pr-5 sm:pr-0 lg:pr-5 font-bold  sm:text-left">
                Looking for ways to improve your business?
              </h2>
            </Fade>
          </div>

          <Fade bottom duration={1000}>
            <div className="w-full sm:w-[60%] flex flex-col items-center text-center  md:mr-9">
              <p className="text-xl sm:text-3xl">Our team is here to help!</p>
              <p className="text-base sm:text-lg">
                So, reach out now and let’s have a chat!
              </p>
              <button
                className="bg-[blueviolet] text-white px-4 py-2 text-base sm:text-xl rounded hover:shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 mt-4"
                onClick={() => navigate("/contact")}
              >
                Get in touch
              </button>
            </div>
          </Fade>
        </div>
      </section>

      <section className="h-auto container  mb-4 px-3">
        <Fade bottom duration={1000}>
          <div>
            <h1 class="lg:text-4xl  text-[blueviolet] tracking-wide  font-bold pb-2 pt-4">
              Related services
            </h1>
          </div>
        </Fade>

        <div className="grid h-auto grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-4 mb-3">
          {QATestingd.map((val, index) => {
            return (
              <Fade bottom duration={1000} key={index}>
                <div
                  className="h-[20rem] bg-slate-50 hover:bg-slate-200 group relative hover:border-b-4 hover:border-blue-200 transition-all duration-300 overflow-hidden hover:shadow-lg cursor-pointer"
                  onClick={() => {
                    navigate(val.Link);
                  }}
                >
                  <div className="absolute top-4 right-4 transform transition-transform duration-300 group-hover:-rotate-45">
                    {val.icon}
                  </div>
                  {/* Positioned at the bottom left */}
                  <div className="absolute bottom-4 left-16 flex flex-col items-start">
                    <img
                      src={val.image}
                      alt="image not showing"
                      className="w-14 mb-2"
                    />
                    <h2 className="text-lg font-medium">{val.text}</h2>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default QATesting;
