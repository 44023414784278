import React from 'react'
import SimplyBookWidget from '../SimplyBookWidge'

function Meeting() {
  return (
    <>
    <SimplyBookWidget/>
    </>
  )
}

export default Meeting
