export default function CommentsContainer() {
  return (
    <div className="max-w-2xl m-auto pt-5 ">
      <h1 className="font-semibold">Leave a Comment</h1>
      <div className="flex flex-col gap-3">
        <div className="flex gap-2 flex-col sm:flex-row">
          <input
            type="text"
            placeholder="Your Name *"
            className="border outline-none p-2 flex-1 rounded-2"
          />
          <input
            type="text"
            placeholder="Your Email *"
            className="border outline-none p-2 flex-1 rounded-2"
          />
        </div>
        <div className="flex-1 ">
          <input
            type="text"
            placeholder="Your website *"
            className="border outline-none w-full p-2 rounded-2"
          />
        </div>
        <textarea
          name="Your Comment *"
          className="border outline-none h-44 p-2 rounded-2"
          placeholder="Your Comment *"
        ></textarea>
      </div>
    </div>
  );
}
