import React, { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import ContentModal from "./ContentModel";
import { toast } from "react-toastify";
import { useAdminContextStates } from "../../../context/AdminContext";

const AllBlogs = () => {
  const { token } = useAdminContextStates();
  const { data, loading } = useFetch(
    "https://tech-creator-backend.onrender.com/api/blogs/"
  );
  const [searchInput, setSearchInput] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [selectedBlogIds, setSelectedBlogIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [removeId, setRemoveId] = useState(null);
  const [showModel, setShowModel] = useState(false);

  useEffect(() => {
    if (data && data.blogs) {
      setFilteredBlogs(data.blogs);
    }
  }, [data]);

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchInput(value);
    filterBlogs(value);
  };

  const filterBlogs = (value) => {
    const filtered = data.blogs.filter(
      (blog) =>
        blog.tags.join(" ").toLowerCase().includes(value) ||
        blog.content.toLowerCase().includes(value) ||
        blog._id.toLowerCase().includes(value)
    );
    setFilteredBlogs(filtered);
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedBlogIds(filteredBlogs?.map((blog) => blog._id));
    } else {
      setSelectedBlogIds([]);
    }
  };

  const handleSelectChange = (blogId) => {
    setSelectedBlogIds((prevSelectedBlogIds) =>
      prevSelectedBlogIds.includes(blogId)
        ? prevSelectedBlogIds.filter((id) => id !== blogId)
        : [...prevSelectedBlogIds, blogId]
    );
  };

  const removeBlog = async (id) => {
    if (!id) {
      toast.error("can't delete the blog");
      return;
    }
    try {
      setRemoveId(id);
      const resp = await fetch(
        `https://tech-creator-backend.onrender.com/api/blogs/delete/${id}`,
        { method: "PUT", headers: { Authorization: `Bearer ${token}` } }
      );
      const json = await resp.json();
      if (resp.ok) {
        toast.success("blog deleted successfully");
        const filterBlog = filteredBlogs?.filter((blog) => blog._id !== id);
        setFilteredBlogs(filterBlog);
      } else {
        toast.error(`${json?.error}`);
      }
    } catch (error) {
      toast.error("Internal server error can't delete the blog");
    } finally {
      setRemoveId(null);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ThreeDots color="#891cf0" height={80} width={80} />
      </div>
    );
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-20 mx-2 px-2">
      <div className="pb-4 bg-white">
        <label htmlFor="table-search" className="sr-only">
          Search
        </label>
        <div className="relative mt-1 flex items-center border rounded w-fit">
          <div className=" inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            className="block ps-10 text-sm outline-none rounded p-2"
            placeholder="Search for items"
            onChange={handleSearchChange}
            value={searchInput}
          />
        </div>
      </div>
      <table className="w-full text-sm text-left rtl:text-right">
        <thead className="text-xs bg-violet-400 text-gray-700">
          <tr>
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <th scope="col" className="px-6 py-3 capitalize">
              image
            </th>
            <th scope="col" className="px-6 py-3 capitalize">
              content
            </th>
            <th scope="col" className="px-6 py-3 capitalize">
              tags
            </th>
            <th scope="col" className="px-6 py-3 capitalize">
              publish
            </th>
            <th scope="col" className="px-6 py-3 capitalize">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredBlogs?.map((blog) => {
            const isChecked = selectedBlogIds.includes(blog._id);
            return (
              <tr key={blog._id} className="bg-white border-b">
                <td className="w-4 p-4">
                  <div className="flex items-center">
                    <input
                      id={`checkbox-${blog._id}`}
                      type="checkbox"
                      className="w-4 h-4"
                      checked={isChecked}
                      onChange={() => handleSelectChange(blog._id)}
                    />
                    <label htmlFor={`checkbox-${blog._id}`} className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <img
                    src={blog?.image?.imageUrl}
                    alt={blog?.image?.altDescription}
                    className="w-14 h-14 rounded-md object-cover"
                  />
                </td>
                <td className="px-6 py-4 w-[300px]">
                  <ContentModal content={blog?.content} />
                </td>
                <td className="px-6 py-4 w-[300px]">
                  {blog?.tags ? blog?.tags?.join(", ") : "No Tags"}
                </td>
                <td className="px-6 py-4 capitalize">
                  {blog?.publish ? "yes" : "no"}
                </td>
                <td className="px-6 py-4 flex gap-3 items-center">
                  <Link
                    to={`/admin/blog/edit/${blog?.title?.replace(/ /g, "-")}`}
                    className="font-medium bg-blue-600 text-white p-2 rounded-md px-3"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => removeBlog(blog?._id)}
                    className="capitalize text-white bg-red-800 p-2 rounded-md"
                    type="button"
                    disabled={blog._id === removeId}
                  >
                    remove
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AllBlogs;
