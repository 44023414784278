import React, { useState } from "react";

export default function FileInput({ file, setFile, setImgAlt }) {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const removeFile = () => {
    setFile(null);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full my-6 gap-4">
      {file ? (
        <div className="relative">
          <img
            src={URL.createObjectURL(file)}
            alt="input-image"
            className="h-72"
          />
          <button
            onClick={() => removeFile(file)}
            className="absolute top-0 right-0 p-1 bg-red-600 text-white rounded-full hover:bg-red-800 w-10 text-2xl"
          >
            &times;
          </button>
        </div>
      ) : (
        <>
          <label
            htmlFor="dropzone-file"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={`flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer ${
              isDragging
                ? "border-blue-500 bg-blue-50"
                : "border-gray-300 bg-gray-50"
            }`}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF</p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleFileChange}
            />
          </label>
        </>
      )}
      <div className="flex flex-col gap-3 w-full ">
        <label htmlFor="imgAlt" className="font-bold">
          Alt Description
        </label>
        <input
          type="text"
          id="imgAlt"
          onChange={(e) => setImgAlt(e.target.value)}
          className="border p-2 flex-1 rounded-md outline-none text-sm"
          placeholder="Enter image alt"
        />
      </div>
    </div>
  );
}
