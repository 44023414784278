import { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useAdminContextStates } from "../../../context/AdminContext";
import { ThreeDots } from "react-loader-spinner";
import FileInput from "./FileInput";
import TagsInput from "./TagsInput";
import { toast } from "react-toastify";

export default function BlogEditor() {
  const { token } = useAdminContextStates();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [imgAlt, setImgAlt] = useState("");
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");

  const handlePublish = async () => {
    const content = editorRef.current.getContent();
    const formData = new FormData();
    formData.append("content", content);
    formData.append("image", file);
    formData.append("altDescription", imgAlt);
    formData.append("tags", tags);
    formData.append("description", description);
    formData.append("title", title);
    setLoading(true);
    try {
      const resp = await fetch(
        `https://tech-creator-backend.onrender.com/api/blogs/create`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const json = await resp.json();
      setLoading(false);

      if (resp.ok) {
        editorRef.current.setContent("");
        setFile(null);
        setImgAlt("");
        setTags([]);
        setDescription("");
        setTitle("");
        toast.success("Posted blog successfully");
      } else {
        toast.error(json?.error);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  return (
    <div className="mt-24 mx-3">
      {loading && (
        <div className="flex justify-center items-center h-screen">
          <ThreeDots color="#891cf0" height={80} width={80} />
        </div>
      )}
      <div className="max-w-4xl m-auto">
        <FileInput file={file} setFile={setFile} setImgAlt={setImgAlt} />
        <div className="mb-5">
          <div className="flex flex-col gap-2 my-2">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              placeholder="Enter Blog Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="border outline-none p-2"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              placeholder="Enter a brief description here..."
              className="border p-2 rounded-md w-full outline-none h-52"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
        </div>
        <Editor
          apiKey="muhvdqcz9lqg5jgayj93eeyismpcu19zu9u38c6rnuz7l98n"
          onInit={(_evt, editor) => {
            setLoading(false);
            return (editorRef.current = editor);
          }}
          initialValue="Write your story"
          init={{
            height: 500,
            file_picker_types: "image",
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help | link ",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
        <TagsInput tags={tags} setTags={setTags} />
        <button
          onClick={handlePublish}
          className="btn btn-primary py-2 my-2 capitalize"
          disabled={loading}
        >
          save
        </button>
      </div>
    </div>
  );
}
