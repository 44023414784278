import React from "react";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import Graphsvg from "../../image/graphic-design.svg";
import { Graphicdata } from "../../Componentss/ServicesCardData/Data.js";
import { useNavigate } from "react-router-dom";
import { Relaservices } from "../../Componentss/ServicesCardData/Data.js";

const grphData = [
  {
    Number: "01",
    title: "Logo design",
    description:
      "Enhance your social media presence with captivating visuals and stellar designs.",
    hoverText:
      "From designing engaging posts to creating complete social media campaigns, our team of experts works with you to create an effective social media presence for your business.",
  },
  {
    Number: "02",
    title: "Brand identity",
    description: "Establish an impactful and consistent image for your brand.",
    hoverText:
      "From the logo and brand colors to typography and brand guidelines, we ensure that your brand identity is consistent across all channels and helps build brand recognition and loyalty.",
  },
  {
    Number: "03",
    title: "Packaging design",
    description:
      "Craft functional and appealing designs that drive sales and attract customers.",
    hoverText:
      "Our team of designers creates visually appealing packaging designs that are practical, unique, and consistent to your brand, helping set your product apart from the competition.",
  },
  {
    Number: "04",
    title: "Social media design",
    description:
      "Enhance your social media presence with captivating visuals and stellar designs.",
    hoverText:
      "From designing engaging posts to creating complete social media campaigns, our team of experts works with you to create an effective social media presence for your business.",
  },
  {
    Number: "05",
    title: "Print design",
    description:
      "Promote your products and services with professional marketing materials.",
    hoverText:
      "Our high-quality print design services include tailor-made brochures, flyers, business cards, and more, all consistent with your brand identity to help you leave a lasting impression on customers.",
  },
  {
    Number: "06",
    title: "Environmental graphics",
    description:
      "Transform your physical spaces with our environmental graphics services.",
    hoverText:
      "Bring life to any interior or exterior with our custom designs that fit your brand and enhance the look and feel of your space. From wall murals and window graphics to wayfinding signage and vehicle wraps, we've got you covered.",
  },
  {
    Number: "07",
    title: "Infographics",
    description:
      "Convert complex data and information into engaging and easy-to-interpret graphics.",
    hoverText:
      "We make data look beautiful! Get visually engaging infographics that communicate complex information and data in a clear and concise manner. Optimized for social media, our designs can drive engagement and brand awareness.",
  },
  {
    Number: "08",
    title: "Motion graphics",
    description:
      "Bring your stories to life with our motion graphics services.",
    hoverText:
      "Our team will create dynamic and visually stunning animations that effectively showcase your brand, products, or services, and engage your audience on a deeper level.",
  },
  {
    Number: "09",
    title: "Graphics and illustrations",
    description:
      "Enhance your brand identity with impactful visuals that are tailored specifically to your needs.",
    hoverText:
      "We devise bespoke graphics and illustrations for your website, social media, and print and marketing collateral that not only complement your brand, but also captivate and enthrall your target audience.",
  },
];

const Graphicdes = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Graphicdesigning Development - TechCreator</title>
        <meta
          name="description"
          content="At TechCreator Software House, we offer custom software development services and solutions to help your business succeed."
        />
        <meta
          name="keywords"
          content="TechCreator, TechCreator web and mobile app development services, web and mobile app development services, TechCreator Software
          Development, TechCreator Software Product Discovery, TechCreator Talent Augmentation, techcreator software house, apps development, software industry, swabi software house, software development services"
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="mt-14 bg-black h-[400px] lg:h-[600px] mb-1 flex flex-col justify-center items-start px-2">
        <Fade left duration={1000}>
          <div className="pt-4 container">
            <div className="w-full lg:w-[80%]">
              <h1 className="text-[blueviolet] lg:pb-2  text-2xl sm:text-4xl md:text-4xl lg:text-7xl  subpixel-antialiased  tracking-wide font-bold">
                Graphic design services
              </h1>
            </div>

            <div className="w-full lg:w-[70%] sm:w-[80%]">
              <p className="text-white pt-2 lg:leading-10  leading-7 text-lg lg:text-2xl">
                Crafting cutting-edge brand identities and graphics to elevate
                your brand and stand out from the crowd.
              </p>
            </div>
          </div>
        </Fade>
      </div>

      <section className="h-[500px]  mb-2 flex justify-center items-center px-2 mt-2">
        <div className="container flex flex-col sm:flex-row  justify-between items-center">
          <Fade left duration={1000}>
            <div className="lg:w-[60%] mb-3 lg:mb-0 sm:w-[70%] ">
              <img src={Graphsvg} alt="svg not showing" />

              <h1 className="text-xl sm:text-5xl sm:leading-relaxed lg:text-7xl md:text-5xl md:leading-relaxed font-bold leading-normal lg:leading-normal text-[blueviolet]">
                Bespoke design  solutions for your business
              </h1>
            </div>
          </Fade>
          <Fade
            bottom
            delay={1000}
            duration={1000}
            style={{ overflow: "hidden" }}
          >
            <div className="lg:w-2/5 sm:w-[50%] xs:w-[90%]">
              <p className="text-base lg:text-xl text-justify text-black md:text-lg">
                Our design process is meticulously crafted to ensure that we
                deliver stunning visuals and effective branding for your
                business. We conduct a comprehensive analysis of your brand,
                target market, and business objectives to come up with
                personalized designs that engage your clients and create a
                lasting impact. Our team of experienced designers works with you
                every step of the way to ensure that your vision is realized,
                and that the final product surpasses your expectations.
              </p>
            </div>
          </Fade>
        </div>
      </section>

      <section className="h-auto container mb-2 px-3 ">
        <Fade bottom duration={1000}>
          <div className="">
            <h1 class="text-xl lg:text-5xl sm:text-4xl  text-[blueviolet] tracking-wide  font-bold pb-2 pt-4">
              Services
            </h1>
          </div>
        </Fade>

        <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-4">
          {grphData.map((item) => {
            return (
              <Fade bottom duration={1000}>
                <div className="bg-slate-50 h-[20rem]  container relative group hover:border-b-4 hover:border-blue-200 transition-all duration-300 overflow-hidden hover:shadow-lg cursor-pointer">
                  <h2 className=" text-8xl text-gray-200 pl-0 lg:pl-10 lg:pt-7 absolute inset-0 transition-opacity duration-300 group-hover:opacity-0">
                    {item.Number}
                  </h2>
                  <div className="flex flex-col justify-center h-full relative z-10 lg:p-6  mt-10 hover:mt-0">
                    <h3 className="font-bold pt-3  text-[blueviolet] text-2xl  transition-all duration-300 group-hover:text-2xl">
                      {item.title}
                    </h3>
                    <p className="text-black text-justify  transition-opacity duration-300 group-hover:opacity-100">
                      {item.description}

                      <span className="hidden group-hover:block text-black transition-all duration-300 ">
                        {item.hoverText}
                      </span>
                    </p>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </section>

      <section className="h-auto container mb-2 px-3 mt-3">
        <Fade bottom duration={1000}>
          <div className="">
            <h1 class="lg:text-5xl  text-[blueviolet] tracking-wide  font-bold pb-2 pt-4">
              Benefits
            </h1>
          </div>
        </Fade>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-3">
          {Graphicdata.map((val, index) => {
            return (
              <Fade bottom duration={1000} key={index}>
                <div className="bg-slate-50 w-full h-[20rem]  relative group hover:border-b-4 hover:border-blue-200 transition-all duration-300 overflow-hidden hover:shadow-lg rounded-lg">
                  <img
                    src={val.image}
                    alt="Image not showing"
                    className="w-auto pl-4 h-auto object-cover mt-3"
                  />
                  <div className="flex flex-col justify-center  h-auto p-4">
                    <h3 className="font-bold text-[blueviolet] text-2xl ">
                      {val.Title}
                    </h3>
                    <p className="text-black text-justify">{val.description}</p>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </section>
      <section className="h-[400px]  flex items-center justify-center py-10">
        <div className="container flex flex-col sm:flex-row items-center ">
          <div className="w-full sm:w-3/5 lg:w-[70%]  mb-2 sm:mr-10 text-center sm:text-left">
            <Fade left duration={1000}>
              <h2 className="text-[blueviolet] text-2xl  md:text-5xl md:leading-normal xs:text-3xl lg:text-6xl lg:leading-normal sm:text-4xl leading-snug sm:leading-relaxed pr-5 sm:pr-0 lg:pr-5 font-bold  sm:text-left">
                Looking for ways to improve your business?
              </h2>
            </Fade>
          </div>

          <Fade bottom duration={1000}>
            <div className="w-full sm:w-[60%] flex flex-col items-center text-center  md:mr-9">
              <p className="text-xl sm:text-3xl">Our team is here to help!</p>
              <p className="text-base sm:text-lg">
                So, reach out now and let’s have a chat!
              </p>
              <button
                className="bg-[blueviolet] text-white px-4 py-2 text-base sm:text-xl rounded hover:shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 mt-4"
                onClick={() => navigate("/contact")}
              >
                Get in touch
              </button>
            </div>
          </Fade>
        </div>
      </section>

      <section className="h-auto container  mb-4 px-3">
        <Fade bottom duration={1000}>
          <div>
            <h1 class=" lg:text-5xl  text-[blueviolet] tracking-wide  font-bold pb-2 pt-4">
              Related services
            </h1>
          </div>
        </Fade>

        <div className="grid h-auto grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-4 mb-3">
          {Relaservices.map((val, index) => {
            return (
              <Fade bottom duration={1000} key={index}>
                <div
                  className="h-[20rem] bg-slate-50 hover:bg-slate-200 group relative hover:border-b-4 hover:border-blue-200 transition-all duration-300 overflow-hidden hover:shadow-lg cursor-pointer"
                  onClick={() => {
                    navigate(val.Link);
                  }}
                >
                  <div className="absolute top-4 right-4 transform transition-transform duration-300 group-hover:-rotate-45">
                    {val.icon}
                  </div>
                 
                  <div className="absolute bottom-4 left-16 flex flex-col items-start">
                    <img
                      src={val.image}
                      alt="image not showing"
                      className="w-14 mb-2"
                    />
                    <h2 className="text-lg font-medium">{val.text}</h2>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Graphicdes;
