import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import CommentsContainer from "./CommentsContainer";
import { Helmet } from "react-helmet";

export default function SingleBlog() {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const { title } = useParams();

  const decodedTitle = title.replace(/\-/g, " ");
  console.log(decodedTitle);

  const fetchRef = useRef(() => {});

  fetchRef.current = async () => {
    setLoading(true);
    const resp = await fetch(
      `https://tech-creator-backend.onrender.com/api/blogs/${decodedTitle}`,
      // `http://localhost:5000/api/blogs/${decodedTitle}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await resp.json();
    if (resp.ok) {
      setBlog(json?.blog);
      setLoading(false);
    } else {
      setBlog(null);
    }
  };

  useEffect(() => {
    fetchRef.current();
  }, [fetchRef, title]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ThreeDots color="#891cf0" height={80} width={80} />
      </div>
    );
  }

  const seoTitle = blog?.title;
  const seoDescription = blog?.description;
  const seoImage = blog?.image?.imageUrl;
  const seoUrl = `https://techcreator.co/blog/${title}`;

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: blog?.title,
    description: blog?.excerpt,
    image: blog?.image?.imageUrl,
    author: {
      "@type": "Person",
      name: blog?.author,
    },
    publisher: {
      "@type": "Organization",
      name: "Techcreator",
      logo: {
        "@type": "ImageObject",
        url: "https://techcreator.co/static/media/logol.fc2d923a31aaf69227b3.png",
      },
    },
    datePublished: blog?.publishedDate,
    dateModified: blog?.modifiedDate,
  };

  return (
    <div>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content={seoImage} />
        <meta property="og:url" content={seoUrl} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="pt-10 !pb-20">
        <div className="bg-black w-full h-60 flex items-center px-20">
          <h1 className="text-white max-w-[900px]">{blog?.title}</h1>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="px-3 mt-5 shadow-sm flex-1 mx-2 sm:mx-5 rounded-md">
            <div className="w-full">
              <img
                src={blog?.image?.imageUrl}
                alt={blog?.image?.altDescription}
                className="w-full object-cover max-h-[500px] rounded-md"
              />
              <div className="flex flex-wrap gap-1 p-4">
                {blog?.tags?.map((tag, index) => (
                  <button
                    key={index}
                    className="border p-2 rounded-md bg-blue-300 hover:text-white hover:bg-blue-800"
                  >
                    {tag}
                  </button>
                ))}
              </div>
              <div dangerouslySetInnerHTML={{ __html: blog?.content }} />
            </div>
          </div>
          <div>
            <CommentsContainer />
          </div>
        </div>
      </div>
    </div>
  );
}
