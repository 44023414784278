import React, { useState } from "react";
import { Helmet } from "react-helmet";
import BlogsFilter from "./BlogFilter";
import useFetch from "../../hooks/useFetch";
import { ThreeDots } from "react-loader-spinner";
import BlogCard from "./BlogCard";
import logo from "../../image/logol.png";

function Blog() {
  const [searchText, setSearchText] = useState("");
  const { data, error, loading } = useFetch(
    "https://tech-creator-backend.onrender.com/api/blogs/"
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ThreeDots color="#891cf0" height={80} width={80} />
      </div>
    );
  }

  const pageTitle = "All Blogs - TechCreator";
  const pageDescription =
    "Read the latest blogs on various topics at TechCreator. Stay updated with our blog collection.";

  const keywords = [
    "TechCreator blogs",
    "latest tech blogs",
    "technology articles",
    "tech news",
    "software development blogs",
    "web development articles",
    "programming tutorials",
    "tech industry updates",
    "AI blogs",
    "machine learning articles",
    "tech tips",
    "coding tips",
  ].join(", ");

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            name: pageTitle,
            description: pageDescription,
            mainEntity: data?.blogs?.map((blog) => ({
              "@type": "BlogPosting",
              headline: blog.title,
              image: blog.image.imageUrl,
              author: {
                "@type": "Person",
              },
              publisher: {
                "@type": "Organization",
                name: "TechCreator",
                logo: {
                  "@type": "ImageObject",
                  url: logo,
                },
              },
              datePublished: blog.publishedAt,
              description: blog.description,
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": `${window.location.origin}/blog`,
              },
            })),
          })}
        </script>
      </Helmet>
      <div className="w-full h-[340px] justify-center flex items-center bg-black ">
        <h1 className="text-white">Our Latest News & Articles</h1>
      </div>
      <div className="flex flex-col-reverse md:flex-row gap-4 mt-20 py-6 !pb-20">
        <div className="flex-1 flex flex-col gap-3 px-5">
          {data?.blogs?.length === 0 && (
            <div className="flex items-center justify-center font-bold text-4xl h-[50vh]">
              No Blogs
            </div>
          )}
          {data?.blogs?.map((blog) => (
            <BlogCard key={blog._id} blog={blog} />
          ))}
        </div>
        {data?.blogs?.length !== 0 && (
          <BlogsFilter setSearchText={setSearchText} searchText={searchText} />
        )}
      </div>
    
    </div>
  );
}

export default Blog;
