import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";

function Socialicon() {
  return (
    <>
      {/* <div className="fixed top-80 md:top-56 lg:top-60 self-center  align-items-center  flex flex-col gap-4 right-3 z-10 ">
        <div className="bg-green-400 p-1 rounded  hover:animate-bounce">
          {" "}
          <a
            href="https://www.upwork.com/agencies/techcreator/"
            target="_blank"
          >
            <FaInstagram className="h-7 md:h-10 w-7 md:w-10 text-white   self-center " />
          </a>
        </div>
        <div className="bg-green-400 p-1 rounded  hover:animate-bounce">
          {" "}
          <a
            href="https://www.upwork.com/agencies/techcreator/"
            target="_blank"
          >
            <FaYoutube className="h-7 md:h-10 w-7 md:w-10 text-red   self-center " />
          </a>
        </div>
        <div className="bg-green-400 p-1 rounded  hover:animate-bounce">
          <a
            href="https://api.whatsapp.com/send?phone=923443942550&text=Hello%20Mindful%20Haven%20psychological%20Organization%20"
            target="_blank"
          >
            <ImWhatsapp className="text-white  w-7 md:w-10 h-7 md:h-10   " />
          </a>
        </div>
        <div className="bg-blue-700 p-1 rounded hover:animate-bounce">
          <a href="https://www.linkedin.com/in/mindful-haven-991b052b4" target="_blank">
            <FaLinkedinIn className=" text-white h-7  md:h-10 w-7 md:w-10 self-center   " />
          </a>
        </div>
      </div> */}

<div className="fixed top-40 md:top-46 lg:top-50 self-center align-items-center flex flex-col gap-4 right-3 z-10">
<div className="bg-gradient-to-r from-red-500 via-red-600 to-red-700 p-1 rounded hover:animate-bounce">
    <a href="mailto:mindfulhaven194@gmail.com" target="_blank">
      <FaEnvelope className="h-7 md:h-10 w-7 md:w-10 text-white self-center" />
    </a>
  </div>
  <div className="bg-gradient-to-r from-yellow-400 via-pink-500 to-purple-600 p-1 rounded hover:animate-bounce">
    <a href="https://www.instagram.com/mindfulhaven2024" target="_blank">
      <FaInstagram className="h-7 md:h-10 w-7 md:w-10 text-white self-center" />
    </a>
  </div>
  <div className="bg-white-700 from-yellow-400 via-pink-500 to-purple-600 p-1 rounded hover:animate-bounce">
    <a href="https://www.facebook.com/profile.php?id=61565149988792&mibextid=ZbWKwL" target="_blank">
      <FaFacebook className="h-7 md:h-10 w-7 md:w-10 text-blue self-center" />
    </a>
  </div>
  <div className="bg-red-600 p-1 rounded hover:animate-bounce">
    <a href="https://www.youtube.com/@Mindfulhaven2024" target="_blank">
      <FaYoutube className="h-7 md:h-10 w-7 md:w-10 text-white self-center" />
    </a>
  </div>
  <div className="bg-green-400 p-1 rounded hover:animate-bounce">
    <a href="https://api.whatsapp.com/send?phone=923443942550&text=Hello%20Mindful%20Haven%20psychological%20Organization" target="_blank">
      <ImWhatsapp className="text-white w-7 md:w-10 h-7 md:h-10" />
    </a>
  </div>
  <div className="bg-blue-700 p-1 rounded hover:animate-bounce">
    <a href="https://www.linkedin.com/in/mindful-haven-991b052b4" target="_blank">
      <FaLinkedinIn className="text-white h-7 md:h-10 w-7 md:w-10 self-center" />
    </a>
  </div>
</div>

    </>
  );
}

export default Socialicon;
