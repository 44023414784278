import { useEffect, useState } from "react";
import { MdOutlinePassword } from "react-icons/md";
import { MdAdminPanelSettings } from "react-icons/md";
import { useAdminContextStates } from "../../../context/AdminContext";
import { useNavigate } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";
import { ColorRing, RotatingLines } from "react-loader-spinner";

export default function AdminLogin() {
  const navigate = useNavigate();
  const { admin, setAdmin, setToken } = useAdminContextStates();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const onChangeHandler = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const resp = await fetch(
        "https://tech-creator-backend.onrender.com/api/admin/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(credentials),
        }
      );
      const json = await resp.json();
      if (!resp.ok) {
        setLoginError(json?.error);
      }
      setLoginError(null);
      setAdmin(json.admin);
      setToken(json.token);
      setLoading(false);
    } catch (error) {
      setLoginError("internal server error");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (admin) {
      navigate("/admin/all-blogs");
    }
  }, [admin]);

  return (
    <div className="h-[80vh] items-center justify-center mt-36">
      <div className="border rounded-md max-w-lg m-auto py-5">
        <div className="flex flex-col items-center">
          <h1 className="font-bold">Admin</h1>
          <MdAdminPanelSettings size={100} color="purple" />
        </div>
        <form class="max-w-sm mx-auto" onSubmit={formSubmit}>
          <label
            for="email-address-icon"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Email
          </label>
          <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <svg
                class="w-4 h-4 text-gray-500 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 16"
              >
                <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
              </svg>
            </div>
            <input
              type="email"
              id="email-address-icon"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5    dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="johnDoe@example.com"
              required
              name="email"
              onChange={onChangeHandler}
              value={credentials.email}
            />
          </div>
          <label
            for="email-address-icon"
            class="block mb-2 text-sm font-medium text-gray-900 my-3"
          >
            Password
          </label>
          <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <MdOutlinePassword size={20} />
            </div>
            <input
              type="password"
              id="email-address-icon"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5    dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@flowbite.com"
              name="password"
              onChange={onChangeHandler}
              value={credentials.password}
            />
          </div>
          {loginError && (
            <div className="bg-red-100 text-red-700 capitalize p-2 my-4 flex items-center justify-evenly rounded-md">
              <p className="m-0 p-0"> {loginError}</p>{" "}
              <FaExclamationTriangle size={40} />
            </div>
          )}
          <button
            disabled={loading}
            type="submit"
            class="my-3 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
          >
            {loading ? (
              <ColorRing
                visible={true}
                height="20"
                width="20"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]}
              />
            ) : (
              "Login"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
