import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAdminContextStates } from "../../context/AdminContext";

const ProtectedAdminRoute = ({ children }) => {
  const { admin } = useAdminContextStates();
  if (!admin) {
    return <Navigate to="/admin-login" replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedAdminRoute;
