import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function BlogCard({
  blog: {
    title,
    description,
    id,
    image: { imageUrl, altDescription },
    tags,
  },
}) {
  return (
    <div>
      <Helmet>
        <meta name="description" content={description} />
        <meta name="keywords" content={tags?.join(", ")} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta
          property="og:url"
          content={`${window.location.origin}/blog/${title}`}
        />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      <div className="flex flex-col gap-3 border rounded-md shadow-sm" key={id}>
        <div className="w-full">
          <img
            src={imageUrl}
            alt={altDescription}
            className="w-full object-cover max-h-[500px] rounded-md"
          />
          <div className="flex flex-wrap gap-1 p-4">
            {tags?.map((tag, index) => (
              <button
                key={index}
                className="border p-2 rounded-md bg-blue-300 hover:text-white hover:bg-blue-800"
              >
                {tag}
              </button>
            ))}
          </div>
        </div>
        <div className="px-4">
          <Link
            to={`/blog/${title.replace(/ /g, "-")}`}
            className="text-black hover:!text-blue-600 "
          >
            <h1 className="text-3xl">{title}</h1>
          </Link>
          <p className="text-justify">{description}</p>
        </div>
      </div>
    </div>
  );
}
