import { createContext, useContext, useEffect, useRef, useState } from "react";

const AdminContext = createContext(null);

const AdminProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (admin) {
      localStorage.setItem("admin", JSON.stringify(admin));
    }
    if (token) {      localStorage.setItem("token", token);
    }
  }, [admin, token]);

  const detailRef = useRef(() => {});

  detailRef.current = async (token) => {
    try {
      const resp = await fetch(
        "https://tech-creator-backend.onrender.com/api/admin/detail",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const json = await resp.json();
      setAdmin(json.details);
    } catch (error) {
    }
  };

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    detailRef.current(localToken);
    setToken(localToken)
  }, [detailRef]);

  return (
    <AdminContext.Provider value={{ admin, setAdmin, setToken, token }}>
      {children}
    </AdminContext.Provider>
  );
};

const useAdminContextStates = () => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error(
      "useAdminContextStates must be use within the AdminProvider"
    );
  }
  return context;
};

export { useAdminContextStates, AdminProvider };
